.Datepicker {
  display: flex;
}

.DateButton {
  width: 56px;
  height: 36px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 1rem;
}

.DateButton.prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23696969' stroke-width='12' stroke-linecap='square' stroke-miterlimit='10'%3E%3Cpath d='M87.5 111l-47-47M40.5 64l47-47'/%3E%3C/g%3E%3C/svg%3E");
}

.DateButton.next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 128 128' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' stroke='%23696969' stroke-width='12' stroke-linecap='square' stroke-miterlimit='10'%3E%3Cpath d='M40.5 17l47 47M87.5 64l-47 47'/%3E%3C/g%3E%3C/svg%3E");
}

.DateButton.calendar {
  border-radius: 0;
  border-left: none;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23696969" viewBox="0 0 448 512"%3E%3Cpath d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/%3E%3C/svg%3E');
}

.DateInput {
  flex: 1;
  width: 100%;
  height: 36px;
  font-family: inherit;
  font-size: 1em;
  text-align: center;
  border: 1px solid #ccc;
}

.DateInput::after {
  content: 'fg';
  display: inline-block;
  width: 2em;
  height: 1em;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Routes {
  list-style: none;
  padding-left: 0;
}
