html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  color: #333;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.5;
  margin: 0.5em 0;
  font-weight: 500;
}

h1 {
  font-size: 25px;
  color: white;
}

h2 {
  font-size: 20px;
  color: #467329;
}

h3 {
  font-size: 16px;
  color: #333333;
  font-weight: 700;
}

h4 {
  font-size: 16px;
  color: #509226;
}

.button {
  display: inline-block;
  border: none;
  font-family: inherit;
  font-size: 1em;
  background-color: #48a045;
  padding: 0.75em 2em;
  color: white;
  text-decoration: none;
}

.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.react-datepicker-wrapper {
  width: 100%;
}

.error-text {
  color: #e06f6f;
}
