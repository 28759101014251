.Footer {
  background-color: #004530;
  color: white;
  font-size: 14px;
}

.FooterLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
}

.Item {
  display: inline-block;
}

.Item + .Item::before {
  content: '|';
}

.Item a {
  text-decoration: none;
  color: inherit;
  transition: 0.5s all ease-out;
  display: inline-block;
  padding: 10px;
}

.Item a:hover {
  text-decoration: underline;
}
