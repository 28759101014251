.Route {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.Time {
  font-weight: bold;
}

.Craft {
  display: flex;
  align-items: center;
}

.Name {
  font-weight: bold;
  display: inline-block;
  width: 105px;
}

.Utilization {
  display: inline-block;
  margin: 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.Utilization.green {
  background-image: linear-gradient(to right, #95ec95 0%, #48a045 100%);
}

.Utilization.orange {
  background-image: linear-gradient(to right, #edde5d 50%, #f09819 100%);
}

.Utilization.red {
  background-image: linear-gradient(to right, #f45c43 0%, #eb3349 100%);
}
