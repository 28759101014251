.Header {
  height: 80px;
  background-color: white;
  padding: 15px 30px;
}

.Logo {
  height: 50px;
  width: auto;
}

@media (max-width: 546px) {
  .Header {
    height: 70px;
    background-color: white;
    padding: 10px 30px;
  }

  .Logo {
    height: 50px;
    width: auto;
  }
}
