.EventsCalendar {
  padding: 1rem 10px;
}

.IframeWrapper {
  overflow: hidden;
  border-radius: 4px;
}

.EventsCalendar iframe {
  border: none;
  width: calc(100% + 80px);
  min-height: 500px;
  margin-left: -30px;
  margin-top: -10px;
}

@media (max-width: 747px) {
  .EventsCalendar iframe {
    margin-left: -40px;
    margin-top: -10px;
  }
}
