.RouteSection {
  padding: 2rem 10px;
}

.RouteToggle {
  display: flex;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.ToggleButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: #eeeeee;
  color: #555555;
  text-align: center;
  border-color: #cccccc;
  border-style: solid;
  border-width: 0;
}

.ToggleButton.selected {
  background-color: #48a045;
  color: white;
  border-color: #4f824f;
}

.Schedule {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
}

.Routes {
  flex: 1;
  margin: 2rem 20px;
}

.Footer {
  text-align: center;
}

@media (max-width: 768px) {
  .Routes + .Routes {
    margin-top: 0;
  }

  .RouteToggle {
    flex-direction: column;
    border: none;
  }

  .ToggleButton {
    border-width: 1px;
    border-radius: 4px;
  }

  .Schedule {
    flex-direction: column;
  }
}
