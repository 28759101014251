.Notifications {
  padding: 2rem 10px;
}

.MessageList {
  list-style: none;
  padding: 0;
}

.Message {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 1rem;
}

.Message + .Message {
  margin-top: 1rem;
}

.MessageBody {
  font-size: 14px;
  color: #7b7b7b;
}
