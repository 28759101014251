.Main {
  min-height: calc(100vh - 116px);
}

.Main_loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal {
  position: relative;
}

.Modal > section {
  max-width: unset;
  width: 100%;
  border-radius: 0;
  padding: 0 calc((100% - 600px) / 2);
}

.Modal > section > footer {
  background-color: white;
}

.Content {
  padding: 5px 20px;
  line-height: 1.5;
}

.Link {
  text-decoration: none;
  font-weight: 600;
  color: #004530;
}
