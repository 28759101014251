.ModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(00, 00, 00, 0.4);
}
.Modal {
  background-color: #fff;
  max-width: 600px;
  width: calc(100% - 25px);
  position: relative;
  border-radius: 4px;
}
.ModalHeader {
  box-sizing: border-box;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  padding: 5px 20px;
}

.ModalHeader h2 {
  font-weight: bold;
}

.ModalContent {
  box-sizing: border-box;
  padding: 5px 20px;
  line-height: 1.5;
}

.ModalFooter {
  box-sizing: border-box;
  padding: 15px 20px;
  position: relative;
  background-color: #e8e8e8;
  text-align: right;
}

.ModalAction {
  font-family: inherit;
  font-size: inherit;
  padding: 10px 15px;
  border-radius: 4px;
  min-width: 90px;
}

.ModalConfirm {
  border: #467329;
  background-color: #467329;
  color: white;
}

.ModalCancel {
  border: none;
  background-color: transparent;
  border: 1px solid #cec7c7;
  margin: 0 10px;
}

.Categories {
  margin-top: 10px;
}

.Categories label {
  display: block;
  font-weight: 600;
  line-height: 25px;
}

.Categories input {
  font-size: 20px;
}
