.Main {
  background-color: #fffcec;
  min-height: calc(100vh - 100px);
}

.PageHeader {
  height: 220px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 170px;
  background-image: url('./App-Hintergrund.jpg');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
}

.PageHeader h1 {
  margin: 0;
}

.Content {
  padding: 20px 10px;
  margin: 0 10px;
  background-color: white;
}

.Action {
  width: calc(50% - 8px);
  padding: 0.5em;
  text-align: center;
}

.Action + .Action {
  margin-left: 16px;
}

.ContentLink {
  color: #467329;
  font-weight: bold;
  text-decoration: none;
}

.CheckList li {
  font-size: 18px;
  line-height: 1.5;
  margin: 1em 0;
}

.CheckList li > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ClipLoader {
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #467329;
  border-bottom-color: transparent;
  display: inline-block;
  animation: clip 0.75s 0s infinite linear;
  animation-fill-mode: both;
}

.CheckAction {
  display: inline-block;
  text-decoration: none;
  padding: 0.5em 1em;
  font-size: 16px;
  margin: 0 5px;
}

.LoaderWrapper {
  display: inline-flex;
  align-items: center;
}

@keyframes clip {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@media (max-width: 800px) {
  .PushAction {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .Action {
    font-size: 14px;
  }
}

@media (max-width: 402px) {
  .Action {
    font-size: 13px;
  }
}
